import Vue from 'vue'
import Vuex from 'vuex'
//import VuexPersistence from 'vuex-persist'
import Enumerable from 'linq'
import Swal from 'sweetalert2'
import router from '@/router'

import '../Model/Day'
import '../Model/Person'
import '../Model/UI'
import '../Model/RegModel'

Vue.use(Vuex)

type State={
  VCard:Person;
  EditCardNo:string;
  VCardList:Person[];
  StoreTime:Date;
  JumpPath:string;
  NetPayPath:string;
  PriviousPath:string;
  OpenID:string;
  WXCode:string;
  SessionID:string;
  ReLogin:boolean;
  Regresult:string;
  socket:SocketConfig;
  Reg:RegConfig;
  Exam:ExamConfig;
}

type SocketConfig={
  isConnected:boolean;
  message:string;
  reconnectError:boolean;
}

type RegConfig={
  OfficeVersion:string;
    OfficeList:vanitem[];
    CurrentOfficeCode:string;
    CurrentOfficeName:string;
    docitems: Array<DocDetail>;
    Result:string;
}

type ExamConfig={
  currentpat:string;
  examlist: Array<UITran>;
  pageindex: number;
  pagecount: number;
  pageid:string;
}

const state = {
  VCard:null,
  EditCardNo:'',
  VCardList: [],
  StoreTime: null,
  JumpPath:'',
  NetPayPath:'',
  PriviousPath:'',
  OpenID:'',
  WXCode:'',
  SessionID:'',
  ReLogin:false,
  Regresult:'',
  socket: {
    // 连接状态
    isConnected: false,
    // 消息内容
    message: '',
    // 重新连接错误
    reconnectError: false
  },
  Reg:{
    OfficeVersion:'',
    OfficeList:[],
    CurrentOfficeCode:'',
    CurrentOfficeName:'',
    docitems:[],
    officeindexmain:0,
    officeindexchild:0,
    Result:''
  },
  Exam: {
    currentpat: '',
    examlist: [],
    pageindex: 0,
    pagecount: 0,
    pageid: ''
  },
};

const mutations = {
  testmutations(state) {
    // 变更状态
    console.log('testmutations');
  },
  store(state) {
    // 变更状态
    state.StoreTime = new Date();
  },
  changecurrentpat(state,patid){
    //debugger;
    state.VCard = state.VCardList.find(vc => vc.PatID == patid);
  },
  send(state, sendbuffer) {
   //debugger;
  //console.log('send:'+sendbuffer.code)
    if (!state.socket.isConnected) {
      return;
    }

    if (state.SessionID == '') {
      if (sendbuffer.code != 0 && sendbuffer.code != 75) {
        return;
      }
    }

    var sp = {
      Code: sendbuffer.code,
      Buffer: JSON.stringify(sendbuffer.data)
    };

    Vue.prototype.$socket.sendObj(sp)
  },
  SOCKET_ONOPEN (state, event)  {
    // 连接打开触发的函数
    console.log('websocket onopen');
    
    Vue.prototype.$socket = event.currentTarget;
    
    // state.StoreTime = new Date();
    // debugger;

    // const loginMac = function (openid,store) {
    //   //debugger;
    //   if (state.SessionID == '') {
    //     // console.log('loginstart')
    //     // console.log('openid:'+openid)
    //     if (openid != null && openid != '') {
    //       var login = {
    //         UserID: openid,
    //       };
    //       store.commit('send',{code:0,data:login});
    //     }
    //     setTimeout(()=>{
    //       loginMac(state.OpenID,store);
    //     }, 2000);
    //   }
    // }

    // loginMac(state.OpenID,this);
  },
  SOCKET_ONCLOSE (state, event)  {
    // 连接关闭触发的函数
    //debugger;
     console.log('websocket closed');
    state.socket.isConnected = false;
    state.SessionID='';
    //console.log(event);
  },
  SOCKET_ONERROR (state, event)  {
    // 连接发生错误触发的函数
    //console.error(state, event)
    console.log('websocket error');
  },
  SOCKET_ONMESSAGE (state, message)  {
    // 收到消息时触发的函数
    //debugger;
    //console.log('receive');
    //console.log(message);
    
    state.socket.message = message

    if (message.ResultFlag) {
      switch (message.NetCode) {
        case -1:
          {
            // if (state.OpenID == '') {
            //   var openid = localStorage.getItem('openid');
            //   if ((!openid) || openid == '') {
            //     //this.getCodeApi();
            //   }
            //   else {
            //     state.OpenID = openid;
            //   }
            // }
            state.socket.isConnected = true;
            if (state.OpenID != '') {
              let login = {
                UserID: state.OpenID,
              };
              this.commit('send', { code: 0, data: login });
            }
          }
          break;
        case 0:
          {
            // console.log(message)
            // debugger;
            if (state.OpenID == '' && message.OpenID != '') {
              state.OpenID = message.OpenID;
              localStorage.setItem('openid', message.OpenID);
            }

            state.VCardList = message.CardList;
            state.VCard = state.VCardList.find(vc => vc.PatID == message.CurrentCard);
            state.SessionID = message.SessionID;
          }
          break;
        case 3:
        case 76:
          {
            state.VCardList = message.CardList;
            state.VCard = state.VCardList.find(vc => vc.PatID == message.CurrentCard);
          }
          break;
        case 73:
          //debugger;
          if (message.WanitemList != null && message.WanitemList.length > 0) {
            state.Reg.OfficeVersion = message.OfficeVersion;
            state.Reg.OfficeList = message.WanitemList;
          }
          break;
        case 74:
          state.Reg.docitems = message.DocList;
          break;
        case 75:
          {
            state.OpenID = message.OpenID;
            localStorage.setItem('openid', message.OpenID);
            let login = {
              UserID: state.OpenID,
            };
            this.commit('send', { code: 0, data: login });
          }
          break;
        case 26:
          {
            //debugger;
            if (message.ResultFlag) {
              switch (message.Receipt.RcpType) {
                case '挂号':
                  {
                    // state.Reg.Result={
                    //   resultcode:message.ResultFlag,
                    //   errmsg:message.ErrMsg,
                    //   success:''
                    // }
                    // if(message.ResultFlag)
                    // {
                    //   state.Regresult='0';
                    // }
                    // else
                    // {
                    //   state.Regresult=message.ErrMsg;
                    // }

                    Swal.fire({
                      title: '挂号成功',
                      icon: 'success',
                      showCancelButton: false,
                      showConfirmButton: false,
                      timer: 1200
                    }).then(() => {
                      router.push({ path: "/GHOffice" });
                    })

                    //('result:' + state.Regresult);
                  }
                  break;
              }
            }
          }
          break;
      }
    }
    else {
      switch (message.NetCode) {
        case 26:
          Swal.fire({
            title: message.ErrMsg,
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1200
          }).then(() => {
            switch (message.CashType) {
              case 4:
                router.push({ path: "/GHOffice" });
                break;
              default:
                router.push({ path: "/" });
                break;
            }
          })
          break;
      }
    }

    var act = netmap[message.NetCode];
    if (act != null) {
      act(message);
    }
  },
  SOCKET_RECONNECT(state, count) {
    // 重新连接触发的函数
    //console.info(state, count)
    console.log('RECONNECT')
  },
  SOCKET_RECONNECT_ERROR(state) {
    // 重新连接失败触发的函数
    state.socket.reconnectError = true;
    console.log('RECONNECT_ERROR')
  },
};
const actions = {
  send(context, sendbuffer){
    var netcode=sendbuffer.code;
    context.commit('send', sendbuffer);
    return new Promise((resolve, reject)=>{
      netmap[netcode]=(message)=>{
        resolve(message);
      };
    })
  },
  testact(context, sendbuffer)
  {
    //console.log(sendbuffer+'进入');
    return new Promise((resolve, reject)=>{
      netmap[99]=()=>{
        //console.log(sendbuffer+'回调');
        resolve('11111');
        context.commit('testmutations');
      };
      setTimeout(() => {
        var act=netmap[99];
        if(act!=null)
        {
          act();
        }
      }, 1000);
    })
  }
};
const getters = {};

const netmap={};

export default new Vuex.Store<State>({
  //plugins: [new VuexPersistence().plugin],
  state, // 状态
  mutations, // 包含多个更新state函数的对象
  actions, // 包含多个队形事件回调函数的对象
  getters, // 包含多个getter计算属性函数的对象
  modules: { // 模块化

  }
})

// setInterval(() => {
//   //debugger;
//   if (state.SessionID == '' && state.socket.isConnected) {
//     console.log('login');
//     //var openid = localStorage.getItem('openid');
//     var openid=state.OpenID;

//     if (openid != null && openid != "") {
//       //debugger;
//       var login = {
//         UserID: openid,
//       };
//       var sp = {
//         Code: 0,
//         Buffer: JSON.stringify(login)
//       };
//       Vue.prototype.$socket.sendObj(sp);
//     }
//   }
// }, 10000)


// const store = new Vuex.Store({
//   state:{
//     Pat: null,
//     MacState:1,
//   },
//   mutations:{
 
//   },
//   actions:{
 
//   },
//   getters:{
 
//   },
//   modules:{
    
//   },
//   plugins: [vuexLocal.plugin]
// })

// export interface State {
//   user: string
//   navigation: string
//   age:number
// }

// const store1 = new Vuex.Store({
//   state: {
//     count: 1
//   },
//   mutations: {
//     increment (state) {
//       // 变更状态
//       state.count++
//     }
//   }
// })

// const state = {
//   user:  'Arnav' ,
//   navigation: '/home',
//   age:10
// }

// const store = new Vuex.Store({
//   state,
  
//   plugins: [new VuexPersistence().plugin]
// })

//export default store

