















































































import { Vue, Component } from 'vue-property-decorator';
import store from '../store/index'
import Swal from 'sweetalert2'

@Component({
  components: {

  }
})

export default class Home extends Vue {
  notetitle: string = '哈尔滨英华医院就诊须知';
  notemsg: string = '1.门诊实行实名制挂号诊疗，通过微信公众号“哈尔滨英华医院”平台可以挂号。<br/>2.普通门诊患者持最近一次全民核酸阴性证明就诊；腔镜、耳鼻喉患者持24小时内单管核酸阴性证明就诊。<br/>3.就诊患者必须携带本人身份证件，“龙江健康码、行程码”须为绿码，证码一致，体温正常(低于37.3℃)，就诊全程佩戴口罩，保持1.5米距离。<br/>4.一般就诊患者单独就诊，60岁以上老人及病情严重患者无法自行就医的可由一人陪护，陪护者要求等同患者。<br/>5.就诊患者就诊时请如实提供个人信息及流行病学史，如隐瞒、虚报或拒绝提供信息造成不良后果的，按照相关法律法规由当事人承担相应的法律责任。';

  menulist: MenuItem[] = [
    {
      title: '挂号',
      icon: 'guahao',
      fontcolor: '#22BBC1',
      // url: 'EpReport',GHOffice
      url: 'GHOffice',
    }, {
      title: '缴费',
      icon: 'qianfeijiaofei',
      fontcolor: '#FF995F',
      url: 'JF',
    },
    // {
    //   title: '用户管理',
    //   icon: 'renwu-zengjia',
    //   fontcolor: '#F79393',
    //   url: 'AddPat',
    // },
    {
      title: '住院押金',
      icon: 'zhuyuan',
      fontcolor: '#22BBC1',
      url: 'ZYNetCash',
    },
    {
      title: '查询交易',
      icon: 'chaxun',
      fontcolor: '#9C8BFD',
      url: 'GetPayList',
    }
  ];

  tableindex: number = 0;

  dialog: boolean = false;

  tablelist: string[] = [
    '业务', '查询'
  ];

  tablemenumlist: MenuItem[][] = [
    [
      // {
      //   title: '核酸单检',
      //   icon: 'hesuan',
      //   fontcolor: '#FFC107',
      //   url: 'hsdj',
      // }, 
      // {
      //   title: '核酸混检',
      //   icon: 'a-shiguanhesuan',
      //   fontcolor: '#78909C',
      //   url: 'hshj',
      // },
      // {
      //   title: '驾驶员体检',
      //   icon: 'tubiaozhizuomoban-127',
      //   fontcolor: 'green',
      //   url: 'jsytj',
      // },
      {
        title: '检验报告单',
        icon: 'teshushenpi',
        fontcolor: '#C0CA33',
        url: 'GetExamList',
      },
      {
        title: '',
        icon: '',
        fontcolor: '',
        url: '',
      },
      {
        title: '',
        icon: '',
        fontcolor: '',
        url: '',
      },
    ],
    []
  ];

  get Pat() {
    return store.state.VCard;
  }

  get childtablelist() {

    let l = this.tablemenumlist[this.tableindex];
    if (l == null) {
      l = [];
    }
    //debugger;
    return l;
  }

  get currentPat() {
    return this.$store.state.VCard;
  }

  turntag(name) {
    //debugger;
    if (this.$store.state.VCardList.length == 0) {
      this.$router.push('CreatePerson');
    }
    else {
      switch (name) {
        case 'hsdj':
        case 'hshj':
        case 'jsytj':
          {
            if (name == 'hsdj' || name == 'hshj') {
              let now = new Date();
              let date1 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 30, 0);
              let date2 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 50, 0);
              let date3 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 30, 0);
              let date4 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 50, 0);

              //debugger
              if (!((now > date1 && now < date2) || (now > date3 && now < date4))) {
                Swal.fire({
                  title: '核酸时间:上午08:30-10:50   下午13:30-15:50',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 2000
                })

                return;
              }
            }

            let payitem = {
              CashType: 5,
              CardNo: this.currentPat.PatID,
              PayCodeList: [name]
            };

            //debugger;

            Swal.fire({
              title: '开始提交',
              timer: 20000,
              timerProgressBar: true,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading()

                this.$store
                  .dispatch('send', { code: 45, data: payitem })
                  .then(msg => {

                    if (msg.ResultFlag) {
                      //Swal.close();

                      if (msg.Amt > 0) {
                        window.location.href = msg.QrCode;
                        // this.$store.state.NetPayPath=msg.QrCode;
                        //         this.$router.push('NetPay');
                      }
                      //Swal.close();
                    }
                    else {
                      //console.log(msg.ErrMsg);
                      Swal.fire({
                        title: msg.ErrMsg,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 1200
                      })
                    }
                  })
              },
              willClose: () => {

              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.fire({
                  title: '交易超时',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 1200
                })
              }
            })
          }
          break;
        default:
          this.$router.push(name);
          break;
      }
    }
  }

  storecache() {
    this.$store.commit('store');
  }

  mounted() {
    // console.log("Home into")
    // console.log(this.Pat)
    //ChangePageTitle('测试');

    // let setTitle = function (t) {
    //   document.title = t;
    //   var i = document.createElement("iframe");
    //   i.style.display = "none";
    //   i.onload = function () {
    //     setTimeout(function () {
    //       i.remove();
    //     }, 9);
    //   };
    //   document.body.appendChild(i);
    // };
    // setTimeout(function () {
    //   setTitle("停车场-有牌车扫码支付");
    // }, 1);
    //debugger;

    // let notetimestr = localStorage.getItem('notetime');

    // var now = new Date();
    // let noteflag = false;
    // if (notetimestr != null && notetimestr != null) {
    //   let time = new Date(notetimestr);

    //   if (now > time) {
    //     noteflag = true;
    //   }
    // }
    // else {
    //   noteflag = true;
    // }

    // if (noteflag) {
    //   this.dialog = true;
    //   let min = now.getMinutes();
    //   now.setMinutes(min + 30);
    //   notetimestr = this.DateToStr(now);

    //   localStorage.setItem('notetime', notetimestr);
    // }

  }

  DateToStr(date) {
    var year = date.getFullYear();//年
    var month = date.getMonth();//月
    var day = date.getDate();//日
    var hours = date.getHours();//时
    var min = date.getMinutes();//分
    var second = date.getSeconds();//秒
    return year + "-" +
      ((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" +
      (day > 9 ? day : ("0" + day)) + " " +
      (hours > 9 ? hours : ("0" + hours)) + ":" +
      (min > 9 ? min : ("0" + min)) + ":" +
      (second > 9 ? second : ("0" + second));
  }
}
