import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/icon/iconfont.css';

import './Model/Day'
import './Model/Person'
import './Model/UI'

// import {
//   Button,
//   Cell, CellGroup,
//   Form ,Field 
//  } from 'vant';

import Vant from 'vant';
import 'vant/lib/index.less';

import vuetify from './plugins/vuetify';
import VueWechatTitle from 'vue-wechat-title'

import config from '@/systemConfig'
//useSocket(config.websocket.url);
import VueNativeSock from 'vue-native-websocket'

//import 'amfe-flexible';

Vue.use(VueNativeSock,config.websocket.url,{
  // 启用Vuex集成,store的值为你的vuex
  store: store,
  // 数据发送/接收使用使用json格式
  format: "json",
  // 开启自动重连
  reconnection: true,
  // 尝试重连的次数
  reconnectionAttempts: Infinity,
  // 重连间隔时间
  reconnectionDelay: 2000,
  // 将数据进行序列化，由于启用了json格式的数据传输这里需要进行重写
  passToStoreHandler: function (eventName, event) {
    //debugger;
    if (!eventName.startsWith('SOCKET_')) { return }
    let method = 'commit';
    let target = eventName.toUpperCase();
    let msg = event;
    if (this.format === 'json' && event.data) {
      msg = JSON.parse(event.data);
      if (msg.mutation) {
        target = [msg.namespace || '', msg.mutation].filter((e) => !!e).join('/');
      } else if (msg.action) {
        method = 'dispatch';
        target = [msg.namespace || '', msg.action].filter((e) => !!e).join('/');
      }
    }
    this.store[method](target, msg);
    this.store.state.socket.message = msg;
  }
});

//Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

// Vue.use(Button);
// Vue.use(Cell);
// Vue.use(CellGroup);
// Vue.use(Form);
// Vue.use(Field);
Vue.use(Vant);

Vue.use(VueWechatTitle);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
