<template>
  <v-app>
    <!-- <router-view /> -->
    <keep-alive>
      <router-view v-wechat-title="$route.meta.title" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-wechat-title="$route.meta.title" v-if="!$route.meta.keepAlive"></router-view>
  </v-app>
  <!-- <transition name="slide-fade">
  </transition>-->
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
body, html {
    height: 100%;
    -webkit-tap-highlight-color: transparent;
}

body {
    line-height: 1.6;
    font-family: '宋体';
}

#app {
  background-color: #FBF9FE;
  //background-color: #F7F8FA;
  // overflow-y:hidden;
  // overflow-x:hidden;
  font-family: '微软雅黑';
}

      .v-application--wrap{
        background-color: #FBF9FE;
      }
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }

.slide-fade {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
}
.slide-fade-enter,
.slide-fade-leave-to {
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
  transform: translateX(-500px) translateY(-500px) rotate(-150deg) scale(0.5);
  opacity: 1;
}
.slide-fade-enter-active {
  background-color: white;
  transition: all 0.6s ease;
}
.slide-fade-leave-active {
  transition: all 0.7s ease;
  background-color: white;
  transform: translateX(500px) translateY(500px) rotate(150deg) scale(1);
  z-index: 100;
}
</style>

