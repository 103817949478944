

  const api= {
    // 开发地址配置
    mockBaseUrl: '',
    // 生成部署地址配置
    prodBaseUrl: '',
  }
  const websocket={	// 下面的三个参数是需要和后端沟通的
    //url: 'ws://localhost:9200/webSocket',
    url: 'ws://scqyhyy.com:9200/webSocket',
    //url: 'ws://61.167.247.134:9200/webSocket',
    login: '',
    passcode: '',
    name: ''
  }


export default {
  api,
  websocket
}
