import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'
import { IsWeixinOrAlipay } from '../tools/extra'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      // 
      title: '首页',
      needid: true,
      needlogin: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      // 
      needid: false,
      needlogin: false,
    }
  },
  {
    path: '/demo',
    name: 'Demo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/vant1.vue'),
    meta: {
      // 
      needid: false,
      needlogin: false,
    }
  },
  {
    path: '/test',
    name: 'Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/test.vue'),
    meta: {
      // 
      needid: false,
      needlogin: false,
    }
  },
  {
    path: '/Error',
    name: 'Error',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Error.vue'),
    meta: {
      // 
      needid: false,
      needlogin: false,
    }
  }
  ,
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      // 
      title: '登录',
      needid: false,
      needlogin: false,
    }
  },
  {
    path: '/GHOffice',
    name: 'GHOffice',
    component: () => import(/* webpackChunkName: "about" */ '../views/GHOffice.vue'),
    meta: {
      // 
      title: '查询科室',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/CreatePerson',
    name: 'CreatePerson',
    component: () => import(/* webpackChunkName: "about" */ '../views/CreatePerson.vue'),
    meta: {
      // 
      title: '添加用户',
      needid: true,
      needlogin: false,
    }
  },
  {
    path: '/Reg',
    name: 'Reg',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reg.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/RegPayConfirm',
    name: 'RegPayConfirm',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegPayConfirm.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/JF',
    name: 'JF',
    component: () => import(/* webpackChunkName: "about" */ '../views/JF.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/AddPat',
    name: 'AddPat',
    component: () => import(/* webpackChunkName: "about" */ '../views/AddPat.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/EpReport',
    name: 'EpReport',
    component: () => import(/* webpackChunkName: "about" */ '../views/EpReport.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/GetPayList',
    name: 'GetPayList',
    component: () => import(/* webpackChunkName: "about" */ '../views/GetPayList.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/GetExamList',
    name: 'GetExamList',
    component: () => import(/* webpackChunkName: "about" */ '../views/GetExamList.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/GetExamDetail',
    name: 'GetExamDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/GetExamDetail.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/NetPay',
    name: 'NetPay',
    component: () => import(/* webpackChunkName: "about" */ '../views/NetPay.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
  {
    path: '/ZYNetCash',
    name: 'ZYNetCash',
    component: () => import(/* webpackChunkName: "about" */ '../views/ZYNetCash.vue'),
    meta: {
      // 
      title: '',
      needid: true,
      needlogin: true,
    }
  },
]
//EpReport.vue
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  //debugger;
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }

  //console.log(from.name + '-' + to.name)

  document.title = "";

  let topage = to.name;
  store.state.PriviousPath = from.path;
  if (to.name != 'Login') {
    store.state.JumpPath = to.path;
  }

  if (to.meta.needid) {
    if (store.state.OpenID == '') {
      //store.state.JumpPath = to.path;
      router.push({ path: '/Login' }, () => { });
      return;
    }
  }

  if (to.meta.needlogin) {
    let isLogin = store.state.VCard;

    if (isLogin == null) {
      router.push({ path: '/CreatePerson' }, () => { });
      return;
    }
  }

  //debugger;


  // let webcode = IsWeixinOrAlipay();
  // if (webcode == 2) {
  //   if (to.name != 'Error') {
  //     router.push({ path: '/Error' }, () => { });
  //   }
  // }

  next();
});

export default router
